/*eslint no-template-curly-in-string: "off"*/

import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { withFormik } from 'formik/dist/index';
import * as yup from 'yup';

function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  });
}

yup.addMethod(yup.string, 'equalTo', equalTo);

const schema = yup.object({
  password: yup.string().required(),
  passwordConfirmed: yup
    .string()
    .equalTo(yup.ref('password')),
});

const PasswordChangeForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  isValid,
  errors,
}) => {
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPassword">
          <Form.Label>New password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={values.password}
            name="password"
            onChange={handleChange}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formPasswordTwo">
          <Form.Label>Confirm new password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={values.passwordConfirmed}
            name="passwordConfirmed"
            onChange={handleChange}
            isInvalid={!!errors.passwordConfirmed}
          />
          <Form.Control.Feedback type="invalid">
            {errors.passwordConfirmed}
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit"className="mt-3 btn-focal w-100 btn-lg">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default withFormik({
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  validationSchema: schema,
})(PasswordChangeForm);
