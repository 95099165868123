import React, { useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";

import { Container, Row, Col, Spinner, Table, Overlay, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { DateRangePicker } from 'react-date-range';
import { subDays, endOfDay, startOfDay, format } from 'date-fns';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

import useFetchBrand from '../../../common/utils/useFetchBrand';
import useFetchBrandAnalytics from '../../../common/utils/useFetchBrandAnalytics';
import useFetchBrandOffersAnalytics from '../../../common/utils/useFetchBrandOffersAnalytics';

import TimeLineChart from '../../TimeLineChart';
import DeleteButton from '../../OfferDeleteButton';

const { ExportCSVButton } = CSVExport;

const BrandDashboardPage = ({ history, match, location, brandId }) => {

  // Date Range Popup
  const [show, setShow] = useState(false);
  const anchorEl = useRef(null);
  const handleDateRangeClick = (event) => { setShow(!show); };
  const handleDateRangeClose = () => { setShow(false); };

  // Parse URL params
  const params = new URLSearchParams(history.location.search);
  const startDateParam = params.get("startDate") ? params.get("startDate") : null;
  const endDateParam = params.get("endDate") ? params.get("endDate") : null;

  const startDate = startOfDay(startDateParam ? Date.parse(startDateParam) : subDays(new Date(), 7)); // -7 days
  const endDate = endOfDay(endDateParam ? Date.parse(endDateParam) : (new Date())); // now
  
  // Chart state
  const [state, setState] = useState([
    {
      startDate,
      endDate,
      key: 'selection'
    }
  ]);

  // Get Brand, Offers & Analytics
  const { brand: brandDetails, loading: loadingBrandDetails, error: errorBrandDetails } = useFetchBrand(brandId);
  const { offers, loading: loadingOffers, error: errorOffers, getBrandOffersAnalytics } = useFetchBrandOffersAnalytics(brandId);
  const { analytics: brandAnalytics, loading, error } = useFetchBrandAnalytics(brandId, startDateParam, endDateParam);

  const columns = [{
    dataField: 'createdAt',
    text: 'Date',
    sort: true,
    formatter: (cell, row) => { return format(Date.parse(cell), 'dd.MM.yy') },
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return (new Date(b)) - (new Date(a));
      }
      return (new Date(a)) - (new Date(b));
    }
  }, {
    dataField: 'id',
    text: 'ID',
    sort: false,
    formatter: (cell, row) => {
      return <Link
        className="offer-analytics-link"
        to={{ pathname: `/brands/${row.publisherId}/offers/${cell}/analytics` }}
        aria-label={`Analytics link to offer ${cell}`}>
        {cell}
      </Link>
    }
  }, {
    dataField: 'title',
    text: 'Title',
    sort: false
  },{
    dataField: 'subtitle',
    text: 'Subtitle',
    sort: false
  }, {
    dataField: 'view_count',
    text: 'Views',
    sort: true,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return Number.parseInt(b) - Number.parseInt(a);
      }
      return Number.parseInt(a) - Number.parseInt(b);
    }
  }, {
    dataField: 'claim_count',
    text: 'Clicks',
    sort: true,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return Number.parseInt(b) - Number.parseInt(a);
      }
      return Number.parseInt(a) - Number.parseInt(b);
    }
  }, {
    formatter: (cell, row) => {
      if(row.status === "active") {
        return <DeleteButton row={row} refreshOffers={getBrandOffersAnalytics} />
      } else {
        return (
          <Link
            to={{ pathname: `/brands/${row.publisherId}/offers/${row.id}/edit` }}
            aria-label="Edit Offer">
            Edit Draft
          </Link>
        );
      }
    },
    dataField: 'actions-options',
    isDummyField: true,
    csvExport: false,
    text: '',
    sort: false,
    searchable: false,
  }];
  
  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'asc'
  }];


  return (
    <>
      <div className="root">
        <Container>
          <Row className="mb-4">
            <Col sm={12} md={9}>
              <Row>
                <Col xs={12}>
                  {/* Brand Details */}
                  <div className="paper">
                    { loadingBrandDetails && <Spinner animation="border" />}
                    { ((!loadingBrandDetails && errorBrandDetails) || (!loadingBrandDetails && !brandDetails)) && <div>{errorBrandDetails ? errorBrandDetails.message : 'Failed to load data'}</div>}
                    { (!loadingBrandDetails && brandDetails) && (
                      <div>
                        <h5 className="mb-4">Brand Details</h5>
                        <div className="d-flex">
                          <span className="d-flex mr-4">{brandDetails.logoUrl ? <img src={brandDetails.logoUrl} alt="brand" className="brand-logo" /> : 'No Image'}</span>
                          <Table className="details-table" responsive>
                            <thead className="details-table-head">
                              <tr>
                                <th className="text-gray">Name</th>
                                <th className="text-gray">Email address</th>
                                <th className="text-gray">Phone number</th>
                                <th className="text-gray"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{brandDetails.name || '-'}</td>
                                <td>{brandDetails.email || '-'}</td>
                                <td>{brandDetails.phone || '-'}</td>
                                <td>
                                  <Link 
                                    to={{ pathname: `/brands/${brandDetails.id}/edit` }}
                                    aria-label="Edit Brand">
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} className="my-4">
                  {/* Date Range */}

                  <div ref={anchorEl} className="float-right">
                    <button 
                      onClick={handleDateRangeClick} 
                      className="date-range-selector">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                      <span className="date-range-value ml-2 dropdown-toggle">{startDate ? format(
                          startDate,
                          'MMM dd, yyyy'
                        ) : '?'} - {endDate ? format(
                          endDate,
                          'MMM dd, yyyy'
                        ) : '?'}</span>
                    </button>


                    <Overlay
                      show={show}
                      target={anchorEl.current}
                      placement="bottom"
                    >
                      <Popover>
                        <DateRangePicker
                          className="date-range-el"
                          placement="bottom"
                          onChange={item => {
                            
                            setState([item.selection]);

                            if(item.selection.startDate < item.selection.endDate){
                              // Range has been selected
                              
                              // Close popup before render
                              handleDateRangeClose();
                              
                              const dateStart = format(
                                startOfDay(item.selection.startDate),
                                `yyyy-MM-dd'T'HH:mm:ss`
                              );
                              
                              const dateEnd = format(
                                endOfDay(item.selection.endDate),
                                `yyyy-MM-dd'T'HH:mm:ss`
                              );

                              // NOTE: This does not convert the value to UTC, only removes the timezone
                              //       Business decision approved as the simplest method of aligning user timezones
                              
                              // Navigate to new route with range
                              history.push(
                                `${history.location.pathname}?startDate=${dateStart}&endDate=${dateEnd}`
                              );
                            }
                            
                          }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          inputRanges = {[]}
                          months={1}
                          ranges={state}
                          direction="horizontal"
                        />
                      </Popover>
                    </Overlay>
                  </div>

                </Col>
                <Col xs={12}>
                  {/* Overview */}
                  <div className="paper">
                    <h5 className="mb-4">Overview</h5>
                    { loading && <Spinner animation="border" /> }
                    { ((!loading && error) || (!loading && (!brandAnalytics || !brandAnalytics.graphData))) && <div>{error ? error.message : 'Failed to load data'}</div>}
                    { (!loading && brandAnalytics && brandAnalytics.graphData) && <Container className="chart-box">
                      <Row>
                        <Col xs={9} className="chart">
                          <TimeLineChart data={brandAnalytics.graphData} colors={d => d.color} />
                        </Col>
                        <Col xs={3} className="chart-legend">
                          <ul className="chart-legend-list">
                            <li className="text-gray">
                              <span className="view-dot" /> Offer Views: <span className="view-stat">{brandAnalytics.viewCount}</span>
                            </li>
                            <li className="text-gray">
                              <span className="claim-dot" /> Offer Claims: <span className="claim-stat">{brandAnalytics.claimCount}</span>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Container> }
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3}>
              {/* More Details */}
              <div className="paper">
                <h5 className="mb-4">More Details</h5>
                { loadingBrandDetails && <Spinner animation="border" />}
                { ((!loadingBrandDetails && errorBrandDetails) || (!loadingBrandDetails && !brandDetails)) && <div>{errorBrandDetails ? errorBrandDetails.message : 'Failed to load data'}</div>}
                { (!loadingBrandDetails && brandDetails) && (
                  <div className="more-info-wrap">
                    <h6 className="text-gray mb-3">Users</h6>
                    <div className="mb-4">{brandDetails.users ? brandDetails.users.join(', ') : 'No Users'}</div>
                    <h6 className="text-gray mb-3">Description</h6>
                    <div className="mb-4">{brandDetails.description || '-'}</div>
                    <h6 className="text-gray mb-3">Tagline</h6>
                    <div className="mb-4">{brandDetails.tagline || '-'}</div>
                  </div> 
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  {/* Offer Table */}
                  <div className="paper mb-4">
                    { loadingOffers && <Spinner animation="border" />}
                    { ((!loadingOffers && errorOffers) || (!loadingOffers && !offers)) && <div>{errorOffers ? errorOffers.message : 'Failed to load offers'}</div>}
                    { (!loadingOffers && offers) &&
                      
                      <ToolkitProvider
                        keyField="id"
                        data={ offers }
                        columns={ columns }
                        bootstrap4
                        exportCSV
                      >
                        {
                          props => (
                            <div>
                              <Row className="mb-4">
                                <Col xs={6} className="d-flex flex-column justify-content-center">
                                  <h5 className="m-0">Offers</h5>
                                </Col>
                                <Col xs={12} sm={6} className="table-controls d-flex justify-content-end">
                                  <ExportCSVButton { ...props.csvProps } className="btn-outline-primary" aria-label="Offers Export">Export CSV <FontAwesomeIcon icon={faDownload} /></ExportCSVButton>
                                </Col>
                              </Row>
                              <BootstrapTable
                                defaultSorted={ defaultSorted }
                                { ...props.baseProps } 
                                bordered={ false } />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                  }
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(BrandDashboardPage);