import { useEffect, useState } from 'react';
import getOfferAsync from '../api/getOffer';

export default function useFetchOffer(brandId, offerId) {
  const [offer, setOffer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getOffer = () =>
    getOfferAsync(brandId, offerId)
      .then(result => {
        if (result.data) {
          setOffer(result.data);
        } else {
          setOffer({});
        }
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  useEffect(() => {
    setLoading(true);
    setError(null);
    getOffer();
  }, []);
  return { offer, loading, error, getOffer, setLoading, setError };
}
