import React, { useState, useRef } from 'react';
import { Link, useParams , withRouter } from 'react-router-dom';

import { Container, Row, Col, Spinner, Table, Overlay, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { DateRangePicker } from 'react-date-range';
import { subDays, endOfDay, startOfDay, format } from 'date-fns';

import useFetchOffer from '../../../common/utils/useFetchOffer';
import useFetchOfferAnalytics from '../../../common/utils/useFetchOfferAnalytics';

import TimeLineChart from '../../TimeLineChart';

// import * as ROUTES from '../../../constants/routes';

const OfferAnalytics = ({ history, match, location, brandId }) => {
  const { offerId } = useParams();
  
  // const { offer, loading, error } = useFetchOffer(brandId, offerId);
  // const { analytics, loading: loadingAnalytics, error: errorAnalytics } = useFetchOfferAnalytics(brandId, offerId);

  // if (loading) return <Loading />;
  // if (error) return <div>{error.message}</div>;

  // Date Range Popup
  const [show, setShow] = useState(false);
  const anchorEl = useRef(null);
  const handleDateRangeClick = (event) => { setShow(!show); };
  const handleDateRangeClose = () => { setShow(false); };

  // Parse URL params
  const params = new URLSearchParams(history.location.search);
  const startDateParam = params.get("startDate") ? params.get("startDate") : null;
  const endDateParam = params.get("endDate") ? params.get("endDate") : null;

  const startDate = startOfDay(startDateParam ? Date.parse(startDateParam) : subDays(new Date(), 7)); // -7 days
  const endDate = endOfDay(endDateParam ? Date.parse(endDateParam) : (new Date())); // now
  
  // Chart state
  const [state, setState] = useState([
    {
      startDate,
      endDate,
      key: 'selection'
    }
  ]);

  // Get Offer & Analytics
  const { offer, loading, error } = useFetchOffer(brandId, offerId);
  const { analytics, loading: loadingAnalytics, error: errorAnalytics } = useFetchOfferAnalytics(brandId, offerId, startDateParam, endDateParam);

  return (
    <>
      <div className="root">
        <Container>
          <Row>
            <Col className="mb-4">
              <Link
                className="text-dark p-2"
                to={{ pathname: `/brands/${brandId}/offers` }}
                aria-label="Back">
                  <FontAwesomeIcon icon={faArrowLeft} size="lg" className="mr-2" /> Back to All Offers
              </Link>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12} md={9}>
              <Row>
                <Col xs={12}>
                  {/* Offer Details */}
                  <div className="paper">
                    { loading && <Spinner animation="border" />}
                    { ((!loading && error) || (!loading && !offer)) && <div>{error ? error.message : 'Failed to load data'}</div>}
                    { (!loading && offer) && (
                      <div>
                        <h5 className="mb-4">Offer Details</h5>
                        <div className="d-flex">
                          <span className="d-flex mr-4">{offer.logoUrl ? <img src={offer.logoUrl} alt="offer" className="offer-logo" /> : 'No Image'}</span>
                          <Table className="details-table" responsive>
                            <thead className="details-table-head">
                              <tr>
                                <th className="text-gray">ID</th>
                                <th className="text-gray">Title</th>
                                <th className="text-gray">Subtitle</th>
                                <th className="text-gray">Code</th>
                                <th className="text-gray">Category</th>
                                <th className="text-gray"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                              <td className="id-cell">{offer.id || '-'}</td>
                              <td>{offer.title || '-'}</td>
                              <td>{offer.subtitle || '-'}</td>
                              <td>{offer.code || '-'}</td>
                              <td>{offer.category || '-'}</td>
                              <td>
                                <Link 
                                  to={{ pathname: location.pathname.replace('analytics', 'edit') }}
                                >
                                Edit
                                </Link>
                              </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} className="my-4">
                  {/* Date Range */}

                  <div ref={anchorEl} className="float-right">
                    <button 
                      onClick={handleDateRangeClick} 
                      className="date-range-selector">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                      <span className="date-range-value ml-2 dropdown-toggle">{startDate ? format(
                          startDate,
                          'MMM dd, yyyy'
                        ) : '?'} - {endDate ? format(
                          endDate,
                          'MMM dd, yyyy'
                        ) : '?'}</span>
                    </button>


                    <Overlay
                      show={show}
                      target={anchorEl.current}
                      placement="bottom"
                    >
                      <Popover>
                        <DateRangePicker
                          className="date-range-el"
                          placement="bottom"
                          onChange={item => {
                            
                            setState([item.selection]);

                            if(item.selection.startDate < item.selection.endDate){
                              // Range has been selected
                              
                              // Close popup before render
                              handleDateRangeClose();
                              
                              const dateStart = format(
                                startOfDay(item.selection.startDate),
                                `yyyy-MM-dd'T'HH:mm:ss`
                              );
                              
                              const dateEnd = format(
                                endOfDay(item.selection.endDate),
                                `yyyy-MM-dd'T'HH:mm:ss`
                              );

                              // NOTE: This does not convert the value to UTC, only removes the timezone
                              //       Business decision approved as the simplest method of aligning user timezones
                              
                              // Navigate to new route with range
                              history.push(
                                `${history.location.pathname}?startDate=${dateStart}&endDate=${dateEnd}`
                              );
                            }
                            
                          }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          inputRanges = {[]}
                          months={1}
                          ranges={state}
                          direction="horizontal"
                        />
                      </Popover>
                    </Overlay>
                  </div>

                </Col>
                <Col xs={12}>
                  {/* Overview */}
                  <div className="paper">
                    <h5 className="mb-4">Overview</h5>
                    { loadingAnalytics && <Spinner animation="border" /> }
                    { ((!loadingAnalytics && errorAnalytics) || (!loadingAnalytics && (!analytics || !analytics.graphData))) && <div>{errorAnalytics ? errorAnalytics.message : 'Failed to load data'}</div>}
                    { (!loadingAnalytics && analytics && analytics.graphData) && <Container className="chart-box">
                      <Row>
                        <Col xs={9} className="chart">
                          <TimeLineChart data={analytics.graphData} colors={d => d.color} />
                        </Col>
                        <Col xs={3} className="chart-legend">
                          <ul className="chart-legend-list">
                            <li className="text-gray">
                              <span className="view-dot" /> Offer Views: <span className="view-stat">{analytics.viewCount}</span>
                            </li>
                            <li className="text-gray">
                              <span className="claim-dot" /> Offer Clicks: <span className="claim-stat">{analytics.claimCount}</span>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Container> }
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={3}>
              {/* More Details */}
              <div className="paper">
                <h5 className="mb-4">More Details</h5>
                { loading && <Spinner animation="border" />}
                { ((!loading && error) || (!loading && !offer)) && <div>{error ? error.message : 'Failed to load data'}</div>}
                { (!loading && offer) && (
                  <div className="more-info-wrap">
                    <h4 className="text-gray mb-3">Tags</h4>
                    <div className="mb-4">{offer.tags ? offer.tags.join(', ') : 'No Tags'}</div>
                    <h4 className="text-gray mb-3">External Link</h4>
                    <div className="text-break mb-4">{offer.externalLink || '-'}</div>
                    <h4 className="text-gray mb-3">Photo</h4>
                    <div className="mb-4">{offer.photoUrl ? <img src={offer.photoUrl} alt="offer" className="w-100" /> : 'No Image'}</div>
                  </div> 
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(OfferAnalytics);
