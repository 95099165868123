import React from 'react';
import { Switch } from 'react-router-dom';

import BrandEdit from './BrandEdit';
import BrandDashboard from './BrandDashboard';
// import BrandShow from './BrandShow';

import AuthorizedRoute from '../AuthorizedRoute';
import * as ROUTES from '../../constants/routes';

const BrandPage = () => {
  return (
    <div>
      <Switch>
        {/* <AuthorizedRoute path={ROUTES.BRANDS} component={BrandShow} exact /> TODO: redirect if ever used */}
        {/* <AuthorizedRoute path={ROUTES.BRAND_SHOW} component={BrandShow} /> */}
        <AuthorizedRoute path={ROUTES.BRAND_EDIT} component={BrandEdit} />
        <AuthorizedRoute path={ROUTES.BRAND_DASHBOARD} component={BrandDashboard} />
      </Switch>
    </div>
  );
};

export default BrandPage;
