import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

function ImageDropzone({
  upload,
  previewUrl,
  compact = false,
  previewUrls = [],
  className,
}) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      upload(acceptedFiles[acceptedFiles.length - 1]);
    },
  });
  const urls = [];
  if (files.length < 1) {
    if (previewUrls) urls.push(...previewUrls);
    if (previewUrl) urls.push(previewUrl);
  }

  const url = [...urls, ...files.map(file => file.preview)].pop();
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );
  const styleWithImage = {};
  if (url) {
    styleWithImage.backgroundImage = `url(${url})`;
    styleWithImage.backgroundSize = 'contain';
    styleWithImage.backgroundRepeat = 'no-repeat';
    styleWithImage.backgroundPosition = 'center';
  }
  return (
    <div className={`dropzone-wrap ${compact ? 'compact' : 'full' } ${className || ''}`}>
      <div
        style={styleWithImage}
        {...getRootProps({
          className: `dropzone d-flex justify-content-center`,
        })}
      >
        {!url && (
          <div className="dropzone-message d-flex flex-column ">
            <FontAwesomeIcon icon={faCamera} className="mb-1" />
            Click to upload
          </div>
        )}
        {(url && !compact) && (
          <div className="dropzone-change d-flex flex-column">
            <div>
              <FontAwesomeIcon icon={faCamera} className="d-block mb-1" />
              <span className="d-block">Update</span>
            </div>
          </div>
        )}

        <input {...getInputProps()} />
      </div>
      {(compact && url) && (
        <div className="change-link" ><Button variant="link" onClick={open}><FontAwesomeIcon icon={faCamera} className="mr-2" />Update</Button></div>
      )}
    </div>
  );
}

export default ImageDropzone;
