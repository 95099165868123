import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { compose, withHandlers } from 'recompose';
import * as _ from 'lodash';

import fetchCategories from '../../../common/utils/useFetchCategories';
import DateTimeInput from '../../Form/DateTimeInput';
import ImageDropzone from '../../Form/ImageDropzone';
import RichTextInput from '../../Form/RichTextInput';
import TagsInput from '../../Form/TagsInput';
import SelectInput from '../../Form/SelectInput';

const schema = yup.object({
  title: yup
    .string()
    .min(1)
    .max(150)
    .required(),
  subtitle: yup
    .string()
    .min(1)
    .max(255)
    .nullable(),
  description: yup
    .string()
    .min(5)
    .max(2048)
    .required(),
  externalLink: yup
    .string()
    .min(5)
    .nullable(),
  redeemType: yup.string().nullable(),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  code: yup
    .string()
    .max(20)
    .nullable(),
  tags: yup.array().nullable(),
  categoryId: yup.number().required(),
});

const OfferForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  isValid,
  errors,
  setFieldValue,
  handleSubmitDraft,
  handleSubmitPublish,
  brandImageUpload,
  productImageUpload,
  title,
}) => {
  return (
    <>
      <Col xl={{ span: 5 }} md={{ span: 6 }} sm={{ span: 12 }}>
        {title && <h5 className="mb-5">{title}</h5>}
        <Form noValidate>
          <Form.Group controlId="formTitle">
            <Form.Label>Offer Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="E.g. 40% off online"
              value={_.get(values, 'title') || ''}
              name="title"
              onChange={handleChange}
              isInvalid={!!_.get(errors, 'title')}
            />
            <Form.Control.Feedback type="invalid">
              {_.get(errors, 'title')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formSubtitle">
            <Form.Label>SubTitle:</Form.Label>
            <Form.Control
              type="text"
              placeholder="E.g. 40% off online"
              value={_.get(values, 'subtitle') || ''}
              name="subtitle"
              onChange={handleChange}
              isInvalid={!!_.get(errors, 'subtitle')}
            />
            <Form.Control.Feedback type="invalid">
              {_.get(errors, 'subtitle')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formDescription">
            <Form.Label>Description:</Form.Label>
            <RichTextInput
              name="description"
              placeholder="E.g. Grain Free Adult Complete Dry Dog Food Lamb and Vegetables. Shop online today and receive 40% off all our food range. Limited time only."
              initialValue={_.get(values, 'description') || ''}
              onChange={setFieldValue}
              className={
                'form-control ' +
                (_.get(errors, 'description') ? 'is-invalid' : '')
              }
            />
            <Form.Control.Feedback type="invalid">
              {_.get(errors, 'description') || ''}
            </Form.Control.Feedback>
          </Form.Group>
          <SelectInput
            errors={errors}
            name="categoryId"
            onChange={setFieldValue}
            initialValue={_.get(values, 'categoryId') || ''}
            title="Category:"
            useFetchOptions={fetchCategories}
          />
          <Form.Group controlId="formTags">
            <Form.Label>Keywords:</Form.Label>
            <Form.Text className="text-muted my-2">
              Enter keywords such as your brand name and product type then press enter.
              <br />
              Add an unlimited amount of keywords.
            </Form.Text>
            <TagsInput
              name="tags"
              placeholder="Add keyword"
              onChange={setFieldValue}
              initialValue={_.get(values, 'tags') || []}
            />
            <Form.Control.Feedback type="invalid">
              {_.get(errors, 'tags')}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId="formStartDate">
                <Form.Label>Start Date:</Form.Label>
                <div
                  className={
                    'form-control ' +
                    (_.get(errors, 'startDate') || ''
                      ? 'is-invalid'
                      : '')
                  }
                >
                  <Field
                    name="startDate"
                    timeFormat={true}
                    initialValue={_.get(values, 'startDate') || ''}
                    component={DateTimeInput}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {_.get(errors, 'startDate')}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formEndDate">
                <Form.Label>End Date:</Form.Label>
                <div
                  className={
                    'form-control ' +
                    (_.get(errors, 'endDate') ? 'is-invalid' : '')
                  }
                >
                  <Field
                    name="endDate"
                    timeFormat={true}
                    initialValue={_.get(values, 'endDate') || ''}
                    component={DateTimeInput}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {_.get(errors, 'endDate')}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="formRedeemType">
            <Form.Label>Redeem Type:</Form.Label>
            <Form.Control
              name="redeemType"
              as="select"
              value={_.get(values, 'redeemType') || ''}
              onChange={handleChange}
              isInvalid={!!_.get(errors, 'redeemType')}
            >
              <option>Choose</option>
              {[
                { id: 'web', name: 'web' },
                { id: 'location', name: 'location' },
              ].map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {_.get(errors, 'redeemType')}
            </Form.Control.Feedback>
          </Form.Group>
          {values.redeemType === 'web' ? (
            <Form.Group controlId="formExternalLink">
              <Form.Label>External Link:</Form.Label>
              <Form.Control
                type="text"
                placeholder="External Link"
                value={_.get(values, 'externalLink') || ''}
                name="externalLink"
                onChange={handleChange}
                isInvalid={!!_.get(errors, 'externalLink')}
              />
              <Form.Control.Feedback type="invalid">
                {_.get(errors, 'externalLink')}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            ''
          )}
          <Form.Group controlId="formTitle">
            <Form.Label>Code:</Form.Label>
            <Form.Control
              type="text"
              value={_.get(values, 'code') || ''}
              name="code"
              onChange={handleChange}
              isInvalid={!!_.get(values, 'code')}
            />
            <Form.Control.Feedback type="invalid">
              {_.get(errors, 'code')}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      <Col xl={{ span: 5 }} md={{ span: 6 }} sm={{ span: 12 }} className="pb-3">
        <div className="mb-5">
          {/* Match header space as form title to align image upload component */}
          <span style={{height: '1.5em'}} className="d-block mb-5"></span>
          <h5 className="mb-3 text-strong">Product Image:</h5>
          <div className="text-small"><span className="text-muted">Upload dimensions: </span>1380 x 844</div>
          <div className="text-small mb-4"><span className="text-muted">Tip: </span>Use high resolution ‘lifestyle’ images showing off the product.</div>
          <ImageDropzone upload={productImageUpload} previewUrl={_.get(values, 'photoUrl') || ''} />
        </div>
        {values.status !== 'active' ? (
            <Row className="mt-4">
              <Col>
                <Button
                  type="button"
                  onClick={handleSubmitPublish}
                  className="btn-focal w-100 py-2"
                >
                  Publish
                </Button>
              </Col>
              <Col>
                <Button
                  type="button"
                  variant="outline-dark"
                  onClick={handleSubmitDraft}
                  className="btn-focal w-100 py-2"
                >
                  Save as draft
                </Button>
              </Col>
            </Row>
          ) : (
            <Button
              type="button"
              className="mt-4 btn-focal btn-lg w-100"
              onClick={handleSubmitPublish}
            >
              Save
            </Button>
          )}
      </Col>
    </>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: values => {
      return values.offer;
    },
    validationSchema: schema,
  }),
  withHandlers({
    handleSubmitDraft: ({ values, onSubmit }) => () => {
      if (values.redeemType === 'web' && values.externalLink)
        delete values.externalLink;
      values.status = 'draft';
      onSubmit(values);
    },
    handleSubmitPublish: ({ values, onSubmit }) => () => {
      if (values.redeemType === 'web' && values.externalLink)
        delete values.externalLink;
      values.status = 'active';
      onSubmit(values);
    },
  }),
)(OfferForm);
