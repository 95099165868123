import React, { useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  comma: 188,
  SPACE: 32,
  TAB: 9,
  enter: 13,
};

const delimiters = [
  KeyCodes.comma,
  KeyCodes.enter,
  KeyCodes.TAB,
  KeyCodes.SPACE,
];

const TagsInput = ({ onChange, initialValue, name, placeholder }) => {
  const [tags, setTags] = useState(
    initialValue
      ? initialValue.map(text => ({ text: text, id: text }))
      : [],
  );

  const handleDelete = i => {
    const value = tags.filter((tag, index) => index !== i);
    setTags(value);
    handleInputChange(value);
  };

  const handleAddition = tag => {
    const value = [
      ...tags,
      { id: tag.id.toLowerCase(), text: tag.text.toLowerCase() },
    ];
    setTags(value);
    handleInputChange(value);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
    handleInputChange(newTags);
  };
  const handleInputChange = tags => {
    if (onChange) {
      onChange(name, tags.map(tag => tag.id));
    }
  };
  return (
    <div>
      <ReactTags
        autofocus={false}
        tags={tags}
        placeholder={placeholder}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
      />
    </div>
  );
};

export default TagsInput;
