import api from '../../api';

export default function uploadOfferPhoto(brandId,offerId,file) {
  const formData = new FormData();
  formData.append('photo', file);
  return api.post(
    `/brands/manage/${brandId}/offers/${offerId}/uploadPhoto`,
    formData
  );
}
