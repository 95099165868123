import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import * as ROUTES from '../../constants/routes';

const Navigation = (props) => {
  const { userInfo, isLoading, brand } = props;
  return (
    isLoading === true ? null : 
      (
        userInfo ? (
          <NavigationAuth brand={brand} />
        ) : (
          <NavigationNonAuth />
        )
      )
  )
};

// TODO: Replace with react-router-dom generatePath()
const NavigationAuth = (props) => (
  <Nav className="justify-content-center">
    <Nav.Item>
      <Nav.Link as={NavLink} exact to={`${ROUTES.BRANDS}/${props.brand.id}/dashboard`}>Dashboard</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link as={NavLink} exact to={`${ROUTES.BRANDS}/${props.brand.id}/offers`}>Offers</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link as={NavLink} to={`${ROUTES.BRANDS}/${props.brand.id}/offers/create`}>Add Offer</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link as={NavLink} exact to={`${ROUTES.BRANDS}/${props.brand.id}/edit`}>Profile</Nav.Link>
    </Nav.Item>
  </Nav>
);

const NavigationNonAuth = () => (
  <Nav className="justify-content-center">
    {/* <Nav.Item>
      <Nav.Link as={NavLink} to={ROUTES.??}>Profile</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link as={NavLink} to={ROUTES.SIGN_UP}>Sign Up</Nav.Link>
    </Nav.Item>*/}
    <Nav.Item>
      <Nav.Link as={NavLink} to={ROUTES.SIGN_IN}>Sign In</Nav.Link>
    </Nav.Item>
  </Nav>
);

export default Navigation;
