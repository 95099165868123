import React, { useState } from 'react';
import RichTextEditor from 'react-rte';

const RichTextInput = ({ onChange, name, initialValue='', className, placeholder }) => {
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(initialValue,"html"),
  );
  const handle = value => {
    setValue(value);
    if (onChange) {
      onChange(name, value.toString('html'));
    }
  };

  return <RichTextEditor value={value} onChange={handle} editorClassName='editor-box' className={'rich-text-editor ' + className} placeholder={placeholder} />;
};

export default RichTextInput;
