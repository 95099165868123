import axios from 'axios';

export const apiDomainUrl =
  process.env.REACT_APP_API_DOMAIN || 'http://localhost:8080';

export default function createProfile(
  userId,
  data,
  referralCode,
  token,
) {
  let query = { params: {}, headers: {} };
  if (referralCode)
    query = { ...query, params: { ...query.params, referralCode } };
  if (token)
    query = {
      ...query,
      headers: { ...token.headers, Authorization: `bearer ${token}` },
    };
  return axios.post(
    `${apiDomainUrl}/v1/users/${userId}/createProfile`,
    data,
    query,
  );
}
