import api from '../../api';

export default function uploadLogo(brandId,file) {
  const formData = new FormData();
  formData.append('logo', file);
  return api.post(
    `/brands/manage/${brandId}/uploadLogo`,
   formData
  );
}
