import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import Header from '../Header';

import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import MissingBrandPage from '../MissingBrand';
import PasswordForgetPage from '../PasswordForget';
import OffersPage from '../Offer';
import AccountPage from '../Account';
// import AdminPage from '../Admin';
import BrandPage from '../Brand';

import * as ROUTES from '../../constants/routes';

import AuthorizedRoute from '../AuthorizedRoute';
import UnauthorizedRoute from '../UnauthorizedRoute';

import NotFoundPage from '../NotFound';

const App = () => (
  <Router>
    <div>
      <Route component={Header} />

      <Switch>
        <AuthorizedRoute exact path="/" component={({ userInfo }) => {
          // AuthorizedRoute will make sure a brand exists
          return (
            <Redirect to={`${ROUTES.BRANDS}/${userInfo.brands[0].id}/dashboard`} />
          );
        }} />
        
        <UnauthorizedRoute path={ROUTES.SIGN_UP} component={SignUpPage} />
        <UnauthorizedRoute path={ROUTES.SIGN_IN} component={SignInPage} />
        <UnauthorizedRoute path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        
        <AuthorizedRoute path={ROUTES.MISSING_BRAND} component={MissingBrandPage} />
        
        <AuthorizedRoute path={ROUTES.OFFERS} component={OffersPage} />
        
        <AuthorizedRoute path={ROUTES.BRANDS} component={BrandPage} />

        <AuthorizedRoute path={ROUTES.ACCOUNT} component={AccountPage} />
        
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

export default App;
