import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import Form from './Form';
import Loading from '../Loading';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

const PasswordChange = ({ history, firebase }) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  if (error) return <div>{error.message}</div>;
  if (loading) return <Loading />;

  const onSubmit = async data => {
    setLoading(true);
    firebase
      .doPasswordUpdate(data.password)
      .then(() => {
        setLoading(false);
        history.push(ROUTES.ACCOUNT);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };
  const firebaseUser = firebase.getUser();
  if (
    firebaseUser &&
    firebaseUser.providerData &&
    firebaseUser.providerData.length > 0 &&
    firebaseUser.providerData.some(
      provider => provider.providerId === 'password',
    )
  ) {
    return (
      <>
        <h2 className="my-4 text-center">Change your password</h2>
        <Form onSubmit={onSubmit} />
      </>
    );
  } else {
    return (
      <Alert variant="danger" className="mt-5 text-center">
        You are not able to change the password
      </Alert>
    );
  }
};

export default compose(
  withRouter,
  withFirebase,
)(PasswordChange);
