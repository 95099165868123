import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import Loading from '../../Loading';
import * as ROUTES from '../../../constants/routes';
import OfferForm from '../OfferForm';
import createOffer from '../../../common/api/createOffer';
import uploadProductLogo from '../../../common/api/uploadProductLogo';
import uploadPhoto from '../../../common/api/uploadOfferPhoto';

const OfferCreate = ({ history, brandId }) => {
  const [photo, setPhoto] = useState(null);
  const [productLogo, setProductLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (error) return <div>{error.message}</div>;
  if (loading) return <Loading />;

  const onSubmit = data => {
    setLoading(true);
    createOffer(brandId, data)
      .then(response => response.data)
      .then(offer => {
        const promises = [];
        if (photo) {
          promises.push(uploadPhoto(brandId, offer.id, photo));
        }
        if (productLogo) {
          promises.push(
            uploadProductLogo(brandId, offer.id, productLogo),
          );
        }
        Promise.all(promises)
          .then(() => {
            history.push(`${ROUTES.BRANDS}/${brandId}/offers`);
          })
          .catch(err => {
            setError(err);
          });
      })
      .catch(err => {
        setError(err);
      });
  };

  const _tempDate = new Date();
  // 1 month in the future
  const futureDate = new Date(_tempDate.setMonth(_tempDate.getMonth() + 1));

  return (
    <Container>
      <Row className="mb-3 paper mt-4">
        <Col xs={12} className="">
          <Row className="d-flex justify-content-around mt-3">
            <OfferForm
              className="mt-3"
              title="Add Offer"
              offer={{ startDate: new Date(), endDate: futureDate }}
              onSubmit={onSubmit}
              brandImageUpload={setProductLogo}
              productImageUpload={setPhoto}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(OfferCreate);
