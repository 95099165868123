import React from 'react';
import ReactDOM from 'react-dom';

import { AuthProvider } from "./react-check-auth";

import './index.scss';
import * as serviceWorker from './serviceWorker';

import { apiDomainUrl } from './api';

import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(
  <AuthProvider authUrl={`${apiDomainUrl}/v1/sessionInfo`}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </AuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
