import { useEffect, useState } from 'react';
import getOffersAnalytics from '../api/getOffersAnalytics';

export default function useFetchBrandOffersAnalytics(brandId) {
  const [offers, setBrandOffers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const getBrandOffersAnalytics = () => {
    return getOffersAnalytics(brandId)
      .then(result => {
        if (result.data) {
          setBrandOffers(result.data);
        } else {
          setBrandOffers([]);
        }
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    getBrandOffersAnalytics();
  }, [brandId]);
  
  return { offers, loading, error, getBrandOffersAnalytics, setLoading, setError };
}
