import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';

import PasswordChange from '../PasswordChange';

const AccountPage = ({ userInfo }) => (
  <Container>
    <Row>
      <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} >
        <PasswordChange />
      </Col>
    </Row>
  </Container>
);

export default AccountPage;
