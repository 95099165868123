import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { format } from 'date-fns';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';

import Loading from '../../Loading';

import useFetchBrandOffersAnalytics from '../../../common/utils/useFetchBrandOffersAnalytics';

import DeleteButton from '../../OfferDeleteButton';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const OfferList = ({ brandId }) => {
  const {
    offers,
    loading,
    error,
    // setError,
    // setLoading,
    getBrandOffersAnalytics,
  } = useFetchBrandOffersAnalytics(brandId);

  const columns = [{
    dataField: 'createdAt',
    text: 'Date',
    sort: true,
    searchable: false,
    formatter: (cell, row) => { return format(Date.parse(cell), 'dd.MM.yy') },
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return (new Date(b)) - (new Date(a));
      }
      return (new Date(a)) - (new Date(b));
    }
  }, {
    dataField: 'id',
    text: 'ID',
    sort: false
  }, {
    dataField: 'title',
    text: 'Title',
    sort: false,
    formatter: (cell, row) => {
      return <Link
        className="offer-analytics-link"
        to={{ pathname: `/brands/${row.publisherId}/offers/${row.id}/analytics` }}
        aria-label={`Analytics for offer ${row.id}`}>
        {cell}
      </Link>
    }
  }, {
    dataField: 'subtitle',
    text: 'Subtitle',
    sort: false,
    // searchable: false,
  }, {
    dataField: 'view_count',
    text: 'Views',
    sort: true,
    searchable: false,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return Number.parseInt(b) - Number.parseInt(a);
      }
      return Number.parseInt(a) - Number.parseInt(b);
    }
  }, {
    dataField: 'claim_count',
    text: 'Clicks',
    sort: true,
    searchable: false,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return Number.parseInt(b) - Number.parseInt(a);
      }
      return Number.parseInt(a) - Number.parseInt(b);
    }
  }, {
    formatter: (cell, row, idx) => {
      if(row.status === "active") {
        return <DeleteButton row={row} refreshOffers={getBrandOffersAnalytics} />
      } else {
        return (
          <Link
            to={{ pathname: `/brands/${row.publisherId}/offers/${row.id}/edit` }}
            aria-label="Edit Offer">
            Edit Draft
          </Link>
        );
      }
    },
    text: '',
    dataField: 'actions-options',
    isDummyField: true,
    csvExport: false,
    sort: false,
    searchable: false,
  }];
  
  const defaultSorted = [{
    dataField: 'createdAt',
    order: 'asc'
  }];

  // const deleteOfferOnClick = (offerId) => {
  //   setLoading(true);
  //   deleteOffer(brandId, offerId)
  //     .then(() => {
  //       setLoading(false);
  //       getOffers();
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //       setError(error);
  //     });
  // };

  if (loading || !offers) return <Loading />;
  if (error) return <div>{error.message}</div>;

  return (
    <Container fluid={true}>
      {/* <OfferBar onSearch={value => setSearch(value)} brandId={brandId} /> */}
      {/* <hr /> */}
      <Row>
        <Col>
          <div className="m-auto paper">

            {offers.length === 0 && (
              <div className="my-3 text-center">
                <p>You have no offers yet.</p>
              </div>
            )}

            {offers.length > 0 && (
              <div className="paper">
              {/* { loadingOffers && <Spinner animation="border" />} */}
              {/* { ((!loadingOffers && errorOffers) || (!loadingOffers && !offers)) && <div>{errorOffers ? errorOffers.message : 'Failed to load offers'}</div>} */}
              <ToolkitProvider
                keyField="id"
                data={ offers }
                columns={ columns }
                bootstrap4
                exportCSV
                search
              >
                {
                  props => (
                    <div>
                      <Row className="mb-4">
                        <Col xs={6} className="d-flex flex-column justify-content-center">
                          <h5 className="m-0">Offers</h5>
                        </Col>
                        <Col xs={12} sm={6} className="table-controls d-flex justify-content-end">
                          <SearchBar { ...props.searchProps } />
                          <ExportCSVButton { ...props.csvProps } className="btn-outline-primary" aria-label="Offers Export">Export CSV <FontAwesomeIcon icon={faDownload} /></ExportCSVButton>
                        </Col>
                      </Row>
                      <BootstrapTable 
                        defaultSorted={ defaultSorted }
                        { ...props.baseProps }
                        bordered={ false } />

                    </div>
                  )
                }
              </ToolkitProvider>
            </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OfferList;
