import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import SignUp from './SignUp';

const SignUpPage = () => (
  <Container>
    <Row>
      <Col className="align-content-center justify-content-center text-center">
        <img
          src="/logo-biz.png"
          className="p-3 pr-5"
          style={{ margin: '3em 0', maxHeight: 160 }}
          alt="logo"
        />
      </Col>
    </Row>
    <Row>
      <Col md={{ span: 6, offset: 3 }}>
        <SignUp />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }}>
        {/* <SignInGoogle /> */}
        {/* <SignInFacebook /> */}
        {/* <SignInTwitter /> */}
        {/* <PasswordForgetLink /> */}
        {/* <SignUpLink /> */}
      </Col>
    </Row>
  </Container>
);

export default SignUpPage;
