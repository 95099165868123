import axios from 'axios';

export const apiDomainUrl =
  process.env.REACT_APP_API_DOMAIN || 'http://localhost:8080';

export default function createProfile(userId, token) {
  let query = { params: { source: 'manage-app' }, headers: {} };
  if (token)
    query = {
      ...query,
      headers: { ...token.headers, Authorization: `bearer ${token}` },
    };
  return axios.post(
    `${apiDomainUrl}/v1/users/${userId}/sendWelcomeEmail`,
    {},
    query,
  );
}
