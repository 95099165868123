import React, { useState } from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { Container, Row, Col, Dropdown, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRight, faArrowLeft, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import Navigation from '../Navigation';
import { AuthConsumer } from '../../react-check-auth';

const styles = {
  image: {
    maxHeight: 100,
  },
  brandName: {
    fontWeight: 900,
  }
};

const IconToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
      // HACK: popper.js - currently we set a min width on the menu
      // to not need this, but if we lose the ability to set a fixed size
      // we should enable this or fix the bug another way
      // setTimeout(forcePopperRender, 1);
    }}
  >
    {children}
  </Button>
));

// React-Bootstrap & Popper.js has a bug on first render to calc the position
// TODO: Investigate full solution if this isn't working performantly
function forcePopperRender() {
  // HACK: Popper.js incorrectly positions on the first render
  window.scrollTo(0, document.documentElement.scrollTop + 1);
  window.scrollTo(0, document.documentElement.scrollTop - 1);
}

const CustomDropdownMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy, brands, brand }, ref) => {
    const [showBrands, setShowBrands] = useState(false);

    function toggleBrandPanel(evt) { setShowBrands(!showBrands); evt.preventDefault(); evt.stopPropagation(); forcePopperRender(); }
    
    // Reset dropdown view to show initial page
    function switchBrand() {
      setShowBrands(false);
    }

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        { showBrands ? (
          <div>
            {/* Brand Switcher */}
            <ul className="list-unstyled">
            {[
              <Button variant="link" onClick={toggleBrandPanel} key={'brand-header'} className="dropdown-item my-2"><FontAwesomeIcon icon={faArrowLeft} className="mr-3" />Brands</Button>]
              .concat(brands.map(b =>
              <Dropdown.Item key={b.id}
                as={Link}
                to={{ pathname: `/brands/${b.id}/dashboard` }}
                aria-label={`Link to ${b.name}`}
                className="dropdown-item mb-2"
                onClick={switchBrand} 
                >
                <span className="mr-3">{b.name}</span>{brand.id === b.id && <FontAwesomeIcon icon={faCheck} className="m-auto" />}
              </Dropdown.Item>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            {/* Main Menu */}
            <Button variant="link" onClick={toggleBrandPanel} className="dropdown-item my-2"><span style={{width: '16px'}} className="d-inline-block mr-3">⇄</span>Switch Brand<FontAwesomeIcon icon={faArrowRight} className="ml-3" /></Button>
            {children}
          </div>
        )}
      </div>
    );
  },
);

// const headerExcludeRegex = /^(?!.*(\/signin))(?!.*(\/signup)).*$/
const show = /^(\/signin|\/signup)/

const Header = ({ firebase, history, match, location }) => {
  
  if(show.test(location.pathname)) {
    // Don't show header on signin or signup
    return null;
  }
  
  return (
    <AuthConsumer>
      {({ userInfo, isLoading, refreshAuth }) => {
        
        if(isLoading || !userInfo) {
          return null;
        }

        // Can return null, we're extracting the brand ID
        const brandMatch = matchPath(location.pathname, {
          path: "/brands/:brandId",
        });
        
        const { brandId } = (brandMatch || { params: {} }).params;
        
        // Set brand
        let brand = {};
        if(brandId && userInfo.brands) {
          brand = userInfo.brands.find(e => e.id === brandId) || {};
        }
  
        const logout = () => {
          firebase
            .doSignOut() // This is a promise that calls a logout API
            .then(() => {
              refreshAuth();
              history.push(ROUTES.SIGN_IN)
            });
        };
  
        return (
          isLoading || !brand ? null : (
            <Container className="mt-2 mb-5">
              <Row>
                <Col className="">
                  <Link
                    to={{ pathname: `/` }}
                    aria-label="Home">
                    <img src="/logo-bw.png" style={styles.image} className="p-3 pr-5" alt="logo" />
                  </Link>
                </Col>
                <Col className="text-center my-auto">
                  {brand.name && (<h5 style={styles.brandName}>{`${brand.name} Portal`}</h5>)}
                </Col>
                {brandId && <Col>
                  <Dropdown alignRight className="user-toplevel-dropdown float-right">
                    <Dropdown.Toggle as={IconToggle}>
                      <span style={{backgroundImage: `url(${brand.logoUrl})`}} className="dropdown-image"></span>
                    </Dropdown.Toggle>
  
                    <Dropdown.Menu as={CustomDropdownMenu} brands={userInfo.brands} brand={brand}>
                      <Dropdown.Item 
                        as={Link}
                        to={{ pathname: `/account` }}
                        aria-label={`Settings Link`}
                        className="dropdown-item mb-2">
                          <FontAwesomeIcon icon={faCog} className="mr-3" />Settings
                      </Dropdown.Item>
                      <Dropdown.Item className="mb-2" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} className="mr-3" />Log out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>}
              </Row>
              {brandId && <Navigation isLoading={isLoading} userInfo={userInfo} brand={brand} />}
            </Container>
          )
        )
      }
      }
    </AuthConsumer>
  )
};

export default compose(
  withRouter,
  withFirebase,
)(Header);
