exports.getDaysInbetweenDatesUTCMap = function getDaysInbetweenDatesUTCMap(startDate, endDate) {
    const dayMap = {};
    const dt = new Date(startDate);
    const dtEnd = new Date(endDate);

    do {

        // Add to map
        dayMap[dt.toISOString()] = {
            day: dt.toISOString(),
            event_count: 0,
            // NOTE: Do not use these values based on the current API response, 
            //       you can't calculate uniques when also grouping by offers
            // user_count: 0,
            // referrer_count: 0,
            events: [],
        };

        // Increment
        dt.setDate(dt.getDate() + 1)
    }
    while (dt < dtEnd);

    return dayMap;
}

exports.convertLocalDateToUTCIgnoringTimezone = function convertLocalDateToUTCIgnoringTimezone(date) {
    const timestamp = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds(),
    );

    return new Date(timestamp);
}

exports.formatToD3Compatible = function formatToD3Compatible(dateString) {
    // console.log(dateString);
    const date = new Date(dateString);
    // console.log(date.toUTCString());
    return `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth()+1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`;
}