import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Loading from '../../Loading';
import * as ROUTES from '../../../constants/routes';
import SignUpForm from '../SignUpForm';
import createProfile from '../../../common/api/createProfile';
import sendWelcomeEmail from '../../../common/api/sendWelcomeEmail';
import startSession from '../../../common/api/startSession';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import pickUser from '../pickUser';
import withRefreshAuthorization from '../../Session/withRefreshAuthorization';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';
const ERROR_CODE_WEAK_PASSWORD = 'auth/weak-password';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them.
`;

const ERROR_MSG_WEAK_PASSWORD = 'The password provided is too weak, please choose another.';

const SignUp = ({ firebase, history, refreshAuth }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (error)
    return (
      <div>
        {error.message}
        <p>
          Try one more time.{' '}
          <Button onClick={() => setError(null)}>Sign Up</Button>
        </p>
      </div>
    );
  if (loading) return <Loading />;

  const onSubmit = async data => {
    setLoading(true);
    const { email, password, referralCode } = data;
    try {
      const {
        user,
      } = await firebase.doCreateUserWithEmailAndPassword(
        email,
        password,
      );
      
      if (!user) throw new Error('Error with firebase');
      
      const token = await user.getIdToken();
      const userId = user.uid;
      
      // Create Profile
      await createProfile(
        userId,
        pickUser(data),
        referralCode,
        token,
      );
      
      // Send Welcome Email
      await sendWelcomeEmail(
        userId,
        token,
      );

      // Start Session
      await startSession(token);
      
      // Refresh local auth
      refreshAuth();
      
      // await firebase.doSendEmailVerification();

      history.push(ROUTES.DASHBOARD);

    } catch (error) {
      if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
      }
      if (error.code === ERROR_CODE_WEAK_PASSWORD) {
        error.message = ERROR_MSG_WEAK_PASSWORD;
      }
      setLoading(false);
      setError(error);
    }
  };
  return (
    <Container>
      <Row>
        <Col>
          <SignUpForm onSubmit={onSubmit} />
        </Col>
      </Row>
    </Container>
  );
};

export default compose(
  withRouter,
  withFirebase,
  withRefreshAuthorization,
)(SignUp);
