import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Loading from '../../Loading';

const SelectInput = ({
  onChange,
  name,
  title,
  initialValue = '',
  useFetchOptions,errors
}) => {
  const [value, setValue] = useState(initialValue);
  const { data, loading, error } = useFetchOptions();

  if (error) return <div>{error.message}</div>;
  if (loading) return <Loading />;


  const handle = event => {
    const {target} = event;
    const {name,value}= target;
    setValue(value);
    if (onChange) {
      onChange(name, value);
    }
  };

  return (
    <Form.Group controlId={name}>
      <Form.Label>{title}</Form.Label>
      <Form.Control name={name} as="select" value={value} onChange={handle} isInvalid={!!errors[name]}>
        <option>Choose</option>
        {data.map(item => (
          <option key={item.id} value={item.id}>{item.name}</option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errors[name]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default SelectInput;
