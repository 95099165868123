import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Form,
  Button,
  Alert,
  Row,
  Col,
} from 'react-bootstrap';

import { withFirebase } from '../Firebase';
import { AuthConsumer } from '../../react-check-auth';
import Loading from '../Loading';
import * as ROUTES from '../../constants/routes';
import startSession from '../../common/api/startSession';

import { PasswordForgetLink } from '../PasswordForget';

/* 
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);
*/

const SignInPage = () => (
  <Row className="d-flex vh-100 justify-content-center m-0 bg-primary">
    <Col sm={12} className="d-flex justify-content-center flex-column">
      <Row>
        {/* <Col className="text-center"><h1>Login</h1></Col> */}
        <Col className="align-content-center justify-content-center text-center">
          <img
            src="/logo-biz.png"
            className="mt-md-0 mt-4"
            style={{ margin: '0 0 3em 0', maxHeight: 100 }}
            alt="logo"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
          <SignInForm />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} className="text-center">
          <PasswordForgetLink />

          {/* <SignInGoogle /> */}
          {/* <SignInFacebook /> */}
          {/* <SignInTwitter /> */}
          {/* <SignUpLink /> */}
        </Col>
      </Row>
    </Col>
    {/* <Col md={6} sm={12} className="d-flex justify-content-center pt-md-0 pt-4 pet-bg">
      <div className="text-center m-auto w-75 text-white">
        <h4 className="mb-4">How we work with brands?</h4>
        <p className="mb-4">Priority Pet operates as a platform for brands to connect with pet parents through offers, discounts and product promotions.</p>
        <p className="mb-4">We do this by tailoring the product the user sees to the profile of the pet they have setup on Priority Pet - making sure brands are getting in front of the right customers.</p>
        <p className="mb-4">Priority Pet helps drive engaged traffic to the brand website or a supporting retailer where the sale is then completed.</p>
        <p className="mb-4">We hope you and your brand enjoy your Priority Pet experience!</p>
      </div>
    </Col> */}
  </Row>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const SignInFormBase = ({ firebase, history }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(INITIAL_STATE);
  if (loading) return <Loading />;

  const onSubmit = (event, refreshAuth) => {
    setLoading(true);
    firebase
      .doSignInWithEmailAndPassword(user.email, user.password)
      .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
      .then(startSession) // Send to our server and start the session
      .then(() => {
        
        setUser(INITIAL_STATE);
        
        refreshAuth();
        
        history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        setError(error);
      });
    setLoading(false);
    event.preventDefault();
  };

  const onChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  return (
    <AuthConsumer>
      {({ refreshAuth }) => (
        <Form onSubmit={e => onSubmit(e, refreshAuth)}>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              value={user.email}
              onChange={onChange}
              type="text"
              placeholder="Email Address"
            />
          </Form.Group>
          <Form.Group controlId="formPassword1">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              value={user.password}
              onChange={onChange}
              autoComplete="current-password"
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Button
            variant="secondary"
            disabled={user.password === '' || user.email === ''}
            type="submit"
            className="mt-3 btn-focal w-100 btn-lg"
          >
            Login
          </Button>
          {error && (
            <Alert variant="danger" className="my-4">
              {error.message}
            </Alert>
          )}
        </Form>
      )}
    </AuthConsumer>
  );
};

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
      .then(startSession) // Send to our server and start the session
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <Button
          variant="primary"
          type="submit"
          className="mt-3 btn-focal w-100 btn-lg"
        >
          Sign In With Google
        </Button>
        {error && <Alert variant="danger">{error.message}</Alert>}
      </Form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
      .then(startSession) // Send to our server and start the session
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <Button
          variant="primary"
          type="submit"
          className="mt-3 btn-focal w-100 btn-lg"
        >
          Login With Facebook
        </Button>
        {error && <Alert variant="danger">{error.message}</Alert>}
      </Form>
    );
  }
}

// class SignInTwitterBase extends Component {
//   constructor(props) {
//     super(props);

//     this.state = { error: null };
//   }

//   onSubmit = event => {
//     this.props.firebase
//       .doSignInWithTwitter()
//       .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
//       .then(startSession) // Send to our server and start the session
//       .then(() => {
//         this.setState({ error: null });
//         this.props.history.push(ROUTES.HOME);
//       })
//       .catch(error => {
//         if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
//           error.message = ERROR_MSG_ACCOUNT_EXISTS;
//         }

//         this.setState({ error });
//       });

//     event.preventDefault();
//   };

//   render() {
//     const { error } = this.state;

//     return (
//       <Form onSubmit={this.onSubmit}>
//     <Button variant="primary" type="submit" className="mt-3 btn-focal w-100 btn-lg">
//     Login With Twitter
//   </Button>
//   {error && <Alert variant="danger">{error.message}</Alert>}
// </Form>
//     );
//   }
// }

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

// const SignInTwitter = compose(
//   withRouter,
//   withFirebase,
// )(SignInTwitterBase);

export default SignInPage;

export {
  SignInForm,
  SignInGoogle,
  SignInFacebook /* , SignInTwitter */,
};
