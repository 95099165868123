import React from 'react';
import { compose } from 'recompose';
import { Switch } from 'react-router-dom';

import { withEmailVerification } from '../Session';

import OfferList from './OfferList';
import CreateOffer from './OfferCreate';
import EditOffer from './OfferEdit';
import OfferAnalytics from './OfferAnalytics';

import * as ROUTES from '../../constants/routes';

import AuthorizedRoute from '../AuthorizedRoute';


const OffersPage = () => (
  <div>
    <Switch>
      <AuthorizedRoute path={ROUTES.OFFERS} component={OfferList} exact />
      <AuthorizedRoute path={ROUTES.OFFER_CREATE} component={CreateOffer}/>
      <AuthorizedRoute path={ROUTES.OFFER_EDIT} component={EditOffer} />
      <AuthorizedRoute path={ROUTES.OFFER_ANALYTICS} component={OfferAnalytics} />
      {/* <AuthorizedRoute path={ROUTES.OFFER_SHOW} component={ShowOffer} /> */}
    </Switch>
  </div>
);

export default compose(
  withEmailVerification,
)(OffersPage);
