import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import config from '../../firebase.config';
import stopSession from '../../common/api/stopSession';
// import getUser from '../../common/api/getUser';

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    // this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);

  // doSignInWithEmailAndPassword = (email, password) => {
  //   const auth = this.auth;
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       await auth.signInWithEmailAndPassword(email, password);
  //       const token = await auth.currentUser.getIdToken();
  //       resolve(token)
  //     } catch (e) {
  //       reject(e);
  //     }
  //   });
  // };

  
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  // doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = async () => {
    await stopSession();
    return this.auth.signOut(); // Return so we can extend the chain later
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () => {
    return this.auth.currentUser.sendEmailVerification({
      url: window.location.origin, // Current domain
    });
  }

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
  getUser = () => this.auth.currentUser;

  // *** Merge Auth and DB User API *** //

//   onAuthUserListener = (next, fallback) =>
//     this.auth.onAuthStateChanged(async userInfo => {
//       if (userInfo) {
//         try {
//           const user = (await getUser(userInfo.uid)).data;
//           userInfo = {
//             uid: userInfo.uid,
//             email: userInfo.email,
//             emailVerified: userInfo.emailVerified,
//             providerData: userInfo.providerData,
//             ...user,
//           };

//           next(userInfo);
//         } catch (e) {
//           fallback(e);
//         }
//       } else {
//         fallback();
//       }
//     });
}

export default Firebase;
