import React from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';

import { AuthConsumer } from '../../react-check-auth';

import { Alert } from 'react-bootstrap';

import Loading from '../../components/Loading';

class AuthorizedRoute extends React.Component {
  render() {
    const { component: Component, userInfo, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => {
          const { brandId } = props.match.params;
          
          return (
            <AuthConsumer>
              {({ userInfo, isLoading, error }) => {
                
                if (!userInfo && !isLoading) {
                  return <Redirect to="/signin" />;
                }

                if (isLoading) {
                  // Is Loading
                  return <Loading />;
                }

                if (!userInfo || !userInfo.brands || !userInfo.brands.length > 0) {
                  return (
                    <div className="">
                      <Alert variant="danger">No Brands Associated</Alert>
                    </div>
                  );
                }

                // Only validate if brand is accessible when they are looking at a brand page
                if (brandId && !userInfo.brands.some(e => e.id === brandId)) {
                  return (
                    <div className="brand-restricted">
                      <Alert variant="danger">You do not have access to this brand</Alert>
                    </div>
                  );
                }

                return <Component {...this.props} userInfo={userInfo} brandId={brandId}/>;
              }}
            </AuthConsumer>
          );
        }}
      />
    );
  }
}

export default AuthorizedRoute;