import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { withFormik, Field } from 'formik/dist/index';
import * as yup from 'yup';
import * as _ from 'lodash';

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  phone: yup.string().nullable(),
  style: yup
    .object({
      primaryColor: yup.string().required(),
      secondaryColor: yup.string().required(),
    })
    .nullable(),
});

const BrandForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  isValid,
  errors,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={_.get(values, 'email') || ''}
          name="email"
          onChange={handleChange}
          isInvalid={!!_.get(errors, 'email')}
        />
        <Form.Control.Feedback type="invalid">
          {_.get(errors, 'email')}
        </Form.Control.Feedback>
      </Form.Group>
      {/* <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="name"
          placeholder="Enter name"
          value={values.name}
          name="name"
          onChange={handleChange}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>*/}
      <Form.Group controlId="formPhone">
        <Form.Label>Phone</Form.Label>
        <Form.Control
          type="phone"
          placeholder="Enter phone"
          value={_.get(values, 'phone') || ''}
          name="phone"
          onChange={handleChange}
          isInvalid={!!_.get(errors, 'phone')}
        />
        <Form.Control.Feedback type="invalid">
          {_.get(errors, 'phone')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formTagline">
        <Form.Label>Tag line</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter tagline"
          value={_.get(values, 'tagline') || ''}
          name="tagline"
          as={Field}
          onChange={handleChange}
          isInvalid={!!_.get(errors, 'tagline')}
        />
        <Form.Control.Feedback type="invalid">
          {_.get(errors, 'tagline')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter description"
          value={_.get(values, 'description') || ''}
          name="description"
          as={Field}
          onChange={handleChange}
          isInvalid={!!_.get(errors, 'description')}
        />
        <Form.Control.Feedback type="invalid">
          {_.get(errors, 'description')}
        </Form.Control.Feedback>
      </Form.Group>
      {/* <Form.Group controlId="formPrimary">
        <Form.Label>Primary Color</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter primary"
          value={_.get(values, 'style.primaryColor') || ''}
          name="style.primaryColor"
          as={Field}
          onChange={handleChange}
          isInvalid={!!_.get(errors, 'style.primaryColor')}
        />
        <Form.Control.Feedback type="invalid">
          {_.get(errors, 'style.primaryColor')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formSecondary">
        <Form.Label>Secondary Color</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter secondary"
          value={_.get(values, 'style.secondaryColor') || ''}
          name="style.secondaryColor"
          as={Field}
          onChange={handleChange}
          isInvalid={!!_.get(errors, 'style.secondaryColor')}
        />
        <Form.Control.Feedback type="invalid">
          {_.get(errors, 'style.secondaryColor')}
        </Form.Control.Feedback>
      </Form.Group> */}
      <Button
        variant="primary"
        type="submit"
        className="mt-3 btn-focal px-5 btn-lg"
      >
        Save Changes
      </Button>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: values => {
    return values.brand;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  validationSchema: schema,
})(BrandForm);
