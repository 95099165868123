import React, { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { withRouter, useParams } from 'react-router-dom';

import pickOffer from '../pickOffer';
import Loading from '../../Loading';
import * as ROUTES from '../../../constants/routes';
import OfferForm from '../OfferForm';
import useFetchOffer from '../../../common/utils/useFetchOffer';
import editOffer from '../../../common/api/editOffer';
import uploadPhoto from '../../../common/api/uploadOfferPhoto';
import uploadProductLogo from '../../../common/api/uploadProductLogo';

const OfferEdit = ({ history, brandId }) => {
  const [photo, setPhoto] = useState(null);
  const [productLogo, setProductLogo] = useState(null);
  const { offerId } = useParams();
  const {
    offer,
    loading,
    error,
    setLoading,
    setError,
  } = useFetchOffer(brandId, offerId);

  if (error) return <div>{error.message}</div>;
  if (loading) return <Loading />;

  const onSubmit = data => {
    setLoading(true);
    
    const promises = [];

    // Add image upload promises
    if (photo) {
      promises.push(uploadPhoto(brandId, offerId, photo));
    }
    if (productLogo) {
      promises.push(uploadProductLogo(brandId, offerId, productLogo));
    }

    // Add content edit promise
    promises.push(editOffer(brandId, offerId, pickOffer(data)));
    
    Promise.all(promises)
      .then(() => {
        history.push(`${ROUTES.BRANDS}/${brandId}/offers`);
      })
      .catch(err => {
        setError(err);
      });

  };

  if (!offer) {
    return <Alert variant="danger">No Offer</Alert>;
  }
  
  return (
    <Container>
      <Row className="mb-3 paper mt-4">
        <Col xs={12} className="">
          <Row className="d-flex justify-content-around mt-3">
            <OfferForm
              className="mt-3"
              title="Edit Offer"
              offer={offer}
              onSubmit={onSubmit}
              brandImageUpload={setProductLogo}
              productImageUpload={setPhoto}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(OfferEdit);
