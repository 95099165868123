import api from '../../api';

export default function uploadProductLogo(brandId, offerId, file) {
  const formData = new FormData();
  formData.append('productLogo', file);
  return api.post(
    `/brands/manage/${brandId}/offers/${offerId}/uploadProductLogo`,
    formData,
  );
}
