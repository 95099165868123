import React, { useState } from 'react';
import DateTime from 'react-datetime-picker';
import moment from 'moment';

const DATE_FORMAT = 'DD-MM-YYYY HH:mm';

const formatDate = value => moment(value).format(DATE_FORMAT);

const FormikDateTime = ({ field, form, initialValue }) => {
  // Allow null input for optional fields
  const [input, setInput] = useState(initialValue ? new Date(initialValue) : null);
  const onFieldChange = value => {
    let dateValue = value;
    setInput(value);
    if (value instanceof moment) {
      dateValue = formatDate(value);
    }
    form.setFieldValue(field.name, dateValue);
  };

  return (
    <DateTime
      value={input}
      id={field.name}
      name={field.name}
      onChange={onFieldChange}
      disableClock={true}
    />
  );
};

export default FormikDateTime;
