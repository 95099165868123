import React from 'react';
import {
  Redirect,
} from 'react-router-dom';

import { Alert } from 'react-bootstrap';

import * as ROUTES from '../../constants/routes';

const MissingBrandPage = ({ userInfo }) => {
  // Has brand - redirect to first
  if(userInfo && userInfo.brands && userInfo.brands.length > 0) {
    return <Redirect to={`${ROUTES.BRANDS}/${userInfo.brands[0].id}/dashboard`} />;
  }
  
  return (
    <div className="brand-missing">
      <Alert variant="danger">There is no brand associated to this user</Alert>
    </div>
  );
};

export default MissingBrandPage;
