import { useEffect, useState } from 'react';
import { subDays, endOfDay, startOfDay } from 'date-fns';

import getBrandAnalyticsAsync from '../api/getBrandAnalytics';

import { getDaysInbetweenDatesUTCMap, convertLocalDateToUTCIgnoringTimezone, formatToD3Compatible } from './common';

export default function useFetchBrandAnalytics(brandId, startDateParam, endDateParam) {
  const [analytics, setBrandAnalytics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const startDate = startOfDay(startDateParam ? Date.parse(startDateParam) : subDays(new Date(), 7)); // -7 days
  const endDate = endOfDay(endDateParam ? Date.parse(endDateParam) : (new Date())); // now

  const getBrandAnalytics = () => {

    // HACK: We ignore the local timezone from the Date
    const startAsUTC = convertLocalDateToUTCIgnoringTimezone(startDate);
    const endAsUTC = convertLocalDateToUTCIgnoringTimezone(endDate);
    
    // Testing
    // console.log('Requesting:');
    // console.log(startAsUTC.toISOString());
    // console.log(endAsUTC.toISOString());
    const startUTCString = startAsUTC.toISOString();
    const endUTCString = endAsUTC.toISOString();

    getBrandAnalyticsAsync(brandId, { startDate: startUTCString, endDate: endUTCString })
    .then(result => {

      setLoading(false);
      
      if (result) {

        const analyticsData = {
          viewCount: 0,
          claimCount: 0,
          offerGroupMap: {},
          // dateGroupMap: {}
        };

        const viewMap = getDaysInbetweenDatesUTCMap(startUTCString, endUTCString);
        const claimMap = JSON.parse(JSON.stringify(viewMap)); // Quick copy of base structure

        // console.log(JSON.stringify(viewMap));
        // console.log(JSON.stringify(claimMap));

        // Override data to the day placeholders
        result.views.forEach(i => {
          // Chart data
          const oldVal = viewMap[i.day];
          if(oldVal) {
            // Add new values
            // console.log('1', oldVal.events);
            oldVal.events.push(i);
            oldVal.event_count += Number.parseInt(i.event_count);
          } else {
            // First time seeing data for this date
            viewMap[i.day] = { 
              day: i.day,
              event_count: Number.parseInt(i.event_count),
              events: [i],
            }
          }

        });

        result.claims.forEach(i => {
          // Chart data
          const oldVal = claimMap[i.day];
          if(oldVal) {
            // Add new values
            // console.log('3', oldVal.events);
            oldVal.events.push(i);
            oldVal.event_count += Number.parseInt(i.event_count);
          } else {
            // First time seeing data for this date
            claimMap[i.day] = { 
              day: i.day,
              event_count: Number.parseInt(i.event_count),
              events: [i],
            }
          }

        });
        
        const viewDataArray = Object.values(viewMap);
        const claimDataArray = Object.values(claimMap);

        const graphData = [
          {
            id: 'Clicks',
            color: '#d8031c',
            data: []
          },
          {
            id: 'Views',
            color: '#01016f',
            data: []
          }
        ];

        viewDataArray.forEach(i => { 
          const evCount = Number.parseInt(i.event_count);
          
          // Add to total count
          analyticsData.viewCount += evCount;
          
          // Add to views data
          // console.log('5', graphData[1].data);
          graphData[1].data.push({ x: formatToD3Compatible(i.day), y: evCount });
        });


        claimDataArray.forEach(i => { 
          const evCount = Number.parseInt(i.event_count);
          
          // Add to total count
          analyticsData.claimCount += evCount;
          
          // Add to claims data
          // console.log('6', graphData[0].data);
          graphData[0].data.push({ x: formatToD3Compatible(i.day), y: evCount });
        });

        analyticsData.graphData = graphData;
        
        // console.log('3');
        // console.log(analyticsData);
        
        setBrandAnalytics(analyticsData);

      } else {
        setBrandAnalytics({});
      }
    })
    .catch(err => {
      setError(err);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    getBrandAnalytics();
  }, [brandId, startDateParam, endDateParam]);
  
  return { analytics, loading, error, getBrandAnalytics, setLoading, setError };
}
