import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { AuthConsumer } from '../../react-check-auth';

import { withFirebase } from '../Firebase';

const withRefreshAuthorization = Component => {
  class WithAuthorization extends React.Component {
    render() {
      return (
        <AuthConsumer>
          {({ refreshAuth }) => (
            <Component refreshAuth={refreshAuth} {...this.props} />
          )}
        </AuthConsumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withRefreshAuthorization;
