import { useEffect, useState } from 'react';
import getBrandAsync from '../api/getBrand';

export default function useFetchBrand(brandId) {
  const [brand, setBrand] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const getBrand = () => {
    return getBrandAsync(brandId)
      .then(result => {
        if (result.data) {
          setBrand(result.data);
        } else {
          setBrand({});
        }
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    getBrand();
  }, [brandId]);
  
  return { brand, loading, error, getBrand, setLoading, setError };
}
