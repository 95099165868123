import { useEffect, useState } from 'react';
import getCategoriesAsync from '../api/getCategories';

export default function useFetchCategories() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const getCategories = () =>
    getCategoriesAsync()
      .then(result => {
        setLoading(false);
        if (result.data) {
          setData(result.data);
        } else {
          setData([]);
        }
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  useEffect(() => {
    setLoading(true);
    setError(null);
    getCategories();
  }, []);
  return {
    data,
    loading,
    error,
    setLoading,
    setError,
    getCategories,
  };
}
