import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';

import ImageDropzone from '../../Form/ImageDropzone';
import Loading from '../../Loading';
import BrandForm from '../BrandForm';
import useFetchBrand from '../../../common/utils/useFetchBrand';
import editBrand from '../../../common/api/editBrand';
import uploadLogo from '../../../common/api/uploadLogo';
// import uploadBackgroundPhoto from '../../../common/api/uploadBackgroundPhoto';
import pickBrand from '../pickBrand';

const BrandEdit = ({ brandId }) => {
  const [logo, setLogo] = useState(null);
  // const [backgroundImage, setBackgroundImage] = useState(null);
  const {
    brand,
    loading,
    error,
    setLoading,
    setError,
    getBrand,
  } = useFetchBrand(brandId);

  if (error) return <div>{error.message}</div>;
  if (loading) return <Loading />;

  const onSubmit = async data => {
    setLoading(true);
    const promises = [];
    if (logo) {
      promises.push(uploadLogo(brandId, logo));
    }
    // if (backgroundImage) {
    //   promises.push(uploadBackgroundPhoto(brandId, backgroundImage));
    // }

    // Push the brand model update last after the image uploads
    promises.push(editBrand(brandId, pickBrand(data)));
    
    Promise.all(promises)
      .then(() => {
        getBrand();
      })
      .catch(err => {
        setError(err);
      });
  };

  if (!brand) {
    return null;
  }
  
  return (
    <Container>
      <Row className="paper mb-3 mt-4">
        <Col xs={12} className="mt-3 mb-5">
          <h5 className="m-0">Brand Details</h5>
        </Col>
        <Col>
          <BrandForm brand={brand} onSubmit={onSubmit} />
        </Col>
        <Col className="">
          <div className="mb-5">
            <h5 className="mb-3" style={{fontSize: 16}}>Logo</h5>
            <ImageDropzone
              compact={true}
              upload={setLogo}
              previewUrl={brand.logoUrl}
            />
          </div>
          {/* <div className="mb-5">
            <h5 className="mb-3">Cover Image</h5>
            <ImageDropzone
              upload={setBackgroundImage}
              previewUrl={brand.backgroundPhotoUrl}
            />
          </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(BrandEdit);
