import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

import deleteOffer from '../common/api/deleteOffer';

export default function DeleteButton({ row, refreshOffers }) {
  const [isDeleting, setDeleting] = useState(false);
  useEffect(() => {
    if (isDeleting) {
      deleteOffer(row.publisherId, row.id)
      .then(() => {
        setDeleting(false);
        refreshOffers();
      });
      // .catch(() => {
        
      // });
    }
  }, [isDeleting]);

  // Modals
  const [showDialog, setShowDialog] = useState(false);
  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);
  
  const handleConfirmDelete = () => {
    setShowDialog(false);
    setDeleting(true);
  }

  return (
    <>
      <Button
        variant="link"
        className="text-danger"
        disabled={isDeleting || showDialog}
        onClick={handleShowDialog}
      >
        {isDeleting ? 'Deleting…' : 'Delete'}
      </Button>

      <Modal show={showDialog} onHide={handleCloseDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {row.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};