import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Button, Form, Alert } from 'react-bootstrap';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <Row className="d-flex vh-100 justify-content-center m-0 bg-primary">
    <Col md={6} sm={12} className="d-flex justify-content-center flex-column">
      <Row>
        <Col className="align-content-center justify-content-center text-center">
          <img
            src="/logo-biz.png"
            className="mt-md-0 mt-4"
            style={{ margin: '0 0 3em 0', maxHeight: 100 }}
            alt="logo"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <PasswordForgetForm />
        </Col>
      </Row>
      <Row className="my-5">
        <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} className="text-center">
          <p>
            <Link to={ROUTES.SIGN_IN} className="text-secondary">Sign In</Link>
          </p>
        </Col>
      </Row>
    </Col>
  </Row>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;
    
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // setValidated(true);


    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    return (

      <Form onSubmit={this.onSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control 
            type="email"
            placeholder="Enter email"
            name="email"
            required
            value={this.state.email}
            onChange={this.onChange} />
          <Form.Control.Feedback type="invalid">Invalid Email</Form.Control.Feedback>
        </Form.Group>
        <Button 
          variant="secondary"
          type="submit"
          disabled={email === ''}
          className="mt-3 btn-focal w-100 btn-lg">
          Reset My Password
        </Button>
        {error && <Alert className="mt-3">{error.message}</Alert>}
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET} className="text-secondary">Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
