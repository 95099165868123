export const DASHBOARD = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';

export const BRANDS = '/brands';
export const BRAND_DASHBOARD = '/brands/:brandId/dashboard';
// export const BRAND_SHOW = '/brands/:brandId/show';
export const BRAND_EDIT = '/brands/:brandId/edit';

export const OFFERS = '/brands/:brandId/offers';
// export const OFFER_SHOW = '/brands/:brandId/offers/:offerId/show';
export const OFFER_CREATE = '/brands/:brandId/offers/create';
export const OFFER_EDIT = '/brands/:brandId/offers/:offerId/edit';
export const OFFER_ANALYTICS = '/brands/:brandId/offers/:offerId/analytics';

// export const ADMIN_DETAILS = '/admin/:id';
export const MISSING_BRAND = '/missing-brand';
